/** imports */
import { env } from 'tsRoot/constants'
import { getValueFromCookie } from 'tsRoot/actions/cookie'

// freelance.mid-works.comにてログインしたときに下記のcookieはuser_idが書き込まれる
const loginId = getValueFromCookie(`midworks_mypage_${env}_login`)

if(loginId && loginId != 'false') {
  // ログインしているときに表示しないElementを削除
  const elements = document.querySelectorAll('.displayNotLoginOnly')
  elements.forEach((element: any) => {element.parentNode.removeChild(element)})
} else {
  // ログインしていないときに表示しないElementを削除
  const elements = document.querySelectorAll('.displayLoginOnly')
  elements.forEach((element: any) => {element.parentNode.removeChild(element)})
}

// 画面がばたつくのを防ぐために、切り替えのwrapクラスをhidden設定しておき、上記の出しわけの処理の後にhiddenを解除
const wrapElements = document.querySelectorAll('.displayAfterLoginCheck')
wrapElements.forEach((element: any) => {element.style.visibility = null})
